import React, {useState} from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import axios from 'axios'

import { Container, FormWrapper, Info, SendButton } from './styles'

axios.create({
  headers: {'Access-Control-Allow-Origin': 'https://us-east1-tribeweb.cloudfunctions.net/ellite-email'}
})

const Contact = () =>{
  const data = useStaticQuery(graphql`
    query{
      site{
        siteMetadata{
          address,
          email,
          phone
        }
      }
    }
  `)

  const [name, setName] = useState()

  function onNameChange(e){
    setName(e.target.value)
  }
  
  async function handleSubmit(e){
    e.preventDefault()
    const form = document.getElementById('contactForm')

    alert(`Thanks ${name} your message was sent!`)
  
    await axios.post("https://us-east1-tribeweb.cloudfunctions.net/ellite-email", { name: "Rafael" })
    form.reset()
  }

  return(
    <Container className="grid section-xl">
      <FormWrapper>
        <form onSubmit={handleSubmit} id="contactForm">
          <label htmlFor="name">
            <span>Name</span>
            <input type="text" name="name" id="nameId" required onChange={onNameChange.bind(this)}/>
          </label>
          <label htmlFor="email">
            <span>Email</span>
            <input type="email" name="email" id="emailId" required/>
          </label>
          <section>
            <label htmlFor="city">
              <span>City</span><br/>
              <input type="text" name="city" id="cityId"/>
            </label>
            <label htmlFor="state">
              <span>State</span><br/>
              <select defaultValue="--">
                <option value="--" disabled>--</option>
                <option value="AL">AL</option>
                <option value="AK">AK</option>
                <option value="AR">AR</option>	
                <option value="AZ">AZ</option>
                <option value="CA">CA</option>
                <option value="CO">CO</option>
                <option value="CT">CT</option>
                <option value="DC">DC</option>
                <option value="DE">DE</option>
                <option value="FL">FL</option>
                <option value="GA">GA</option>
                <option value="HI">HI</option>
                <option value="IA">IA</option>	
                <option value="ID">ID</option>
                <option value="IL">IL</option>
                <option value="IN">IN</option>
                <option value="KS">KS</option>
                <option value="KY">KY</option>
                <option value="LA">LA</option>
                <option value="MA">MA</option>
                <option value="MD">MD</option>
                <option value="ME">ME</option>
                <option value="MI">MI</option>
                <option value="MN">MN</option>
                <option value="MO">MO</option>	
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="NC">NC</option>	
                <option value="NE">NE</option>
                <option value="NH">NH</option>
                <option value="NJ">NJ</option>
                <option value="NM">NM</option>			
                <option value="NV">NV</option>
                <option value="NY">NY</option>
                <option value="ND">ND</option>
                <option value="OH">OH</option>
                <option value="OK">OK</option>
                <option value="OR">OR</option>
                <option value="PA">PA</option>
                <option value="RI">RI</option>
                <option value="SC">SC</option>
                <option value="SD">SD</option>
                <option value="TN">TN</option>
                <option value="TX">TX</option>
                <option value="UT">UT</option>
                <option value="VT">VT</option>
                <option value="VA">VA</option>
                <option value="WA">WA</option>
                <option value="WI">WI</option>	
                <option value="WV">WV</option>
                <option value="WY">WY</option>
              </select>
            </label>
          </section>
          <label htmlFor="subject">
            <span>Subject</span>
            <input type="text" name="subject" id="subjectId" required/>
          </label>
          <label htmlFor="message">
            <span>Message</span>
            <textarea name="message" id="messageId" cols="30" rows="10" required></textarea>
          </label>

          <SendButton type="submit">
            <span className="box-shadow">Send</span>
          </SendButton>
        </form>
      </FormWrapper>
      <Info>
          <h3>General Information</h3>
          <address>{data.site.siteMetadata.address}</address>
          <a href={`mailto:${data.site.siteMetadata.email}`}>{data.site.siteMetadata.email}</a>
          <a href={`tel:${data.site.siteMetadata.phone}`}>{data.site.siteMetadata.phone}</a>
      </Info>
    </Container>
  )
}

export default Contact